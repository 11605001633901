<template>
	<div>
		<div class="album-head ListBox">
			<div class="album-title">{{album_name}}</div>
			<div class="album-desc">
				<div class="flex-ct">
					<img :src="author&&author.avatar || require('../../assets/image/avatar.png')" alt="">
					<span>{{author&&author.name}}</span>
				</div>
				<div>
					粉丝数量：{{author&&author.fans}}
				</div>
				<div>
					专辑张数：{{total}}张
				</div>
			</div>
		</div>
		<div class="ListBox">
			<chartlet-list
				ref="list"
				:colNum = "tabsActiveNmae"
				:chartletObj="chartletObj"
				:loading="loading"
				:listStatus="listStatus"
				@nextPage="nextPage"
			></chartlet-list>
		</div>
	</div>
</template>
<script>
import chartletList from "./componts/chartletList.vue"
import { pictureGetAlbumPicList } from "../../Api";

export default {
  name: "chartletAlbum",
  components: {
    chartletList,
  },
  data() {
    return {
			tabsActiveNmae: "123",
			loading: false,
			listStatus: {
				isData: true,	// 判断是否有下一页
				isNull: false	// 判断是否为空
			},
			getListParams: {
				id: "",
				page: 0,
			},
			author: {},
			total: 0,
			album_name: "",
			chartletObj: {
				total: 0,
				per_page: 20,
				current_page: 0,
				data: [],
			},
    };
  },
  created() {
		if (this.$route.query.detailId) {
			this.getListParams.page = 1;
			this.getListParams.id = this.$route.query.detailId;
		}
  },
  methods: {
		// 下一页
		nextPage() {
			this.getListParams.page += 1;
		},
		// 获取数据
		getList() {
			this.loading = true;
			this.listStatus.isNull = false;
			this.listStatus.isData = true;
			if ( this.getListParams.page == 1 ) {
				this.$refs.list&&this.$refs.list.clear();
			}
			
			pictureGetAlbumPicList(this.getListParams)
				.then((res) => {
					this.loading = false;
					let data = res.data;
					this.chartletObj = data;
					this.author = data.author;
					this.total = data.total;
					this.album_name = data.album_name;
					if (data.total == 0) {
						this.$refs.list.clear();
						this.listStatus.isNull = true;
					} else if (data.current_page == data.last_page) {
						this.listStatus.isData = false;
					}
				})
				.catch((error) => {
					console.log("错误", error);
				});
		},
  },
	watch: {
		getListParams: {
			handler: function () {
				this.getList()
			},
			deep: true,
		},
	},
};
</script>
<style scoped>
::v-deep .is-animated {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.album-title {
	margin: 0.32rem 0 0.23rem 0;
	text-align: center;
	font-size: 0.36rem;
	font-weight: 600;
	color: rgba(0, 0, 0, 1);
}
.album-desc {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 0.32rem;
}
.album-desc>div {
	margin: 0 0.4rem;
	font-size: 0.14rem;
	font-weight: 400;
	color: rgba(56, 56, 56, 1);
}
.flex-ct img{
	width: 0.3rem;
	height: 0.3rem;
	object-fit: cover;
	border-radius: 0.15rem;
	margin-right: 0.15rem;
}
</style>
